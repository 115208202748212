import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import Rating from "common/components/Rating";
import PropTypes from "prop-types";
import {
  DesignDetailsHeader,
  DesignDetailsWrapper,
} from "./design_details.style";
import { useTranslation } from "react-i18next";

const DesignDetails = (props) => {
  
  const { t, i18n } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          designs {
            title
            subtitle_en
            subtitle_fr
            link
            task_en
            task_fr
            date
            rating
            image_urls
            pdfs
            category_keys
          }
        }
      }
    }
  `);

  const { designs } = data.allVisuellaJson.nodes[0];

  const isBrowser = () => typeof window !== "undefined";

  const link = isBrowser() && window.location.href.split("/").pop();

  const design = designs.filter(function (item) {
    return item.link === link;
  });

  
  return (
    <DesignDetailsWrapper
      id="design"
      style={{
        "--paddingTop": "160px",
        "--paddingBottom": "60px",
      }}
    >
      <Container>
        {design.map(
          (
            {
              title,
              subtitle_en,
              subtitle_fr,
              task_en,
              task_fr,
              date,
              rating,
              image_urls,
              pdfs,
              category_keys,
            },
            index
          ) => (
            <>
              <DesignDetailsHeader>
                <Heading as="h1" content={title} />
                <Heading as="h3" content={i18n.language === "en" ? subtitle_en : subtitle_fr} />
                <div className="meta-row">
                  <Rating rating={rating} />
                  <span className="date">{date}</span>
                </div>
                <h4>{t('common.task')}</h4>
                <p className="description">{i18n.language === "en" ? task_en : task_fr}</p>
              </DesignDetailsHeader>
              {image_urls.map((image_url) => {
                return (
                  <img
                    key={image_url}
                    src={image_url}
                    alt={title}
                    sizes="(max-width: 350px) 350px, (max-height: 400px) 400px"
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px 10px 0 0",
                      display: "block",
                      paddingBottom: "20px",
                    }}
                    loading="lazy"
                  />
                );
              })}
              {pdfs.map((pdf) => {
                return (
                  <iframe
                    src={pdf}
                    width="100%"
                    height="100%"
                    type="application/pdf"
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "calc(100vh - 80px)",
                      objectFit: "cover",
                      borderRadius: "10px 10px 0 0",
                      display: "block",
                      paddingBottom: "20px",
                    }}
                    className="pdf-embed"
                  />
                );
              })}
            </>
          )
        )}
      </Container>
    </DesignDetailsWrapper>
  );
};

DesignDetails.propTypes = {
  key: PropTypes.string,
};

DesignDetails.defaultProps = {
  key: "",
};

export default DesignDetails;
