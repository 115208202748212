import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const DesignDetailsWrapper = styled.section`
  overflow: hidden;
  padding-bottom: var(--paddingBottom);
  padding-top: var(--paddingTop);
  .container {
    > header {
      max-width: 100%;
      @media only screen and (max-width: 480px) {
        max-width: 340px;
      }
    }
    .view-all {
      color: ${themeGet("colors.linkColor")};
      font-weight: 700;
      font-size: 15px;
      display: inline-flex;
      padding-top: 40px;
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        justify-content: center;
        width: 100%;
      }
      i {
        line-height: 1;
        transform: translateX(2px);
        transition: 0.3s ease 0s;
      }
      &:hover i {
        transform: translateX(5px);
      }
      @media only screen and (min-width: 1024px) and (max-width: 1366px) {
        margin-top: 15px;
      }
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: 0;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 15px;
      }
      @media only screen and (max-width: 480px) {
        margin-top: 10px;
      }
      .view-all-icon {
        padding-left: 5px;
      }
    }
  }
`;

export const DesignDetailsHeader = styled.header`
    max-width: 400px;
    width: 100%;
    margin: 0 auto 58px;
    text-align: left; /* Changed from center to left */
    @media only screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
    h1 {
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
        color: ${themeGet("colors.headingColor")};
        margin: 0;
        letter-spacing: -1px;
        @media only screen and (max-width: 1366px) {
            font-size: 38px;
            letter-spacing: -0.7px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 30px;
            letter-spacing: -0.5px;
        }
    }
    h5 {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 12px;
        letter-spacing: 1.5px;
        color: ${themeGet("colors.linkColor")};
        text-transform: uppercase;
        @media only screen and (max-width: 991px) {
            font-size: 13px;
            margin-bottom: 10px;
        }
    }
    h2 {
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: ${themeGet("colors.headingColor")};
        margin: 0;
        letter-spacing: -1px;
        @media only screen and (max-width: 1366px) {
            font-size: 28px;
            letter-spacing: -0.7px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -0.5px;
        }
    }
    h3 {
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        color: ${themeGet("colors.black")};
        margin: 0;
        padding-top: 16px;
        @media only screen and (max-width: 1366px) {
            font-size: 16px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 14px;
            line-height: 28px;
        }
    }
    h4 {
        font-size: 16px;
        line-height: 30px;
        font-weight: 900;
        color: ${themeGet("colors.black")};
        margin: 0px 0px -15px 0px;
        padding-top: 16px;
        @media only screen and (max-width: 1366px) {
            font-size: 16px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 28px;
        }
    }
    p {
        font-size: 16px;
        color: ${themeGet("colors.black")};
        @media only screen and (max-width: 1366px) {
            font-size: 15px;
        }
    }
    .meta-row {
      display: flex;
      align-items: center;
      gap: 10px; /* Add spacing between the date and rating */
      margin-top: 10px;
    }
    .rating { 
      flex-shrink: 0;
      @media only screen and (max-width: 480px) {
        margin-top: 2px;
      }
      i {
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
        @media only screen and (max-width: 480px) {
          svg {
            width: 14px;
            height: auto;
          }
        }
      }
      .star {
        color: ${themeGet("colors.starYellow")};
      }
      .star-o {
        color: ${themeGet("colors.starGrey")};
      }
`;
