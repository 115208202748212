import React from "react";
import DesignDetails from "../../containers/visuella/DesignDetails";

const DesignPage = (props) => {
  const { key } = props.params;
  return (
    <>
      <DesignDetails key={key}/>
    </>
  );
};
export default DesignPage;
